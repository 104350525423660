.loader{
    display: none;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #3d3d3dad;
    min-height: 100vh;
    min-width: 100%;
    z-index: 1000;
    backdrop-filter: blur(10px);
}
.loader.show{
    display: flex;
}
.loader p{
    font-size: 2em;
    color: white;
}